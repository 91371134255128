<template>
  <dir class="BlogListItem">
    <div class="top" v-if="IsAdmin">
      <button
        @click="Edit(Title, Body, Id)"
        class="btn btn-primary Btn AnimationBtn"
      >
        <font-awesome-icon icon="edit" />
        <span> {{ $store.state.resource.ButtonEdit }}</span>
      </button>
      <button
        @click="Del(Title, Body, Id)"
        class="btn btn-danger Btn AnimationBtn"
      >
        <font-awesome-icon icon="trash" />
        <span>{{ $store.state.resource.ButtonDel }}</span>
      </button>
    </div>
    <div
      class="Ovrlay"
      :style="{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${require('@/assets/images/Image.jpeg')})`,
      }"
    ></div>
    <div class="content mt-3">
      <h2 v-if="Title != null">
        {{ Title.length > 30 ? Title.slice(0, 30) + "..." : Title }}
      </h2>
      <p class="mt-0" dir="rtl" v-if="Body != null">
        {{ Body.length > 70 ? Body.slice(0, 70) + "..." : Body }}
      </p>
      <div class="d-flex w-100 justify-content-evenly">
        <router-link
          :to="`/Blog/${Id}`"
          class="btn BtnDesign2 AnimationBtn"
          tag="button"
          style="width: 180px"
        >
          <font-awesome-icon icon="arrow-right" />
          <span>{{ $store.state.resource.ButtonRead }}</span>
        </router-link>
      </div>
    </div>
  </dir>
</template>

<script>
import BlogServices from "../../services/BlogServices.js";
export default {
  name: "ListItem",
  props: ["Title", "Body", "Id"],
  data() {
    return {
      IsAdmin: false,
    };
  },
  methods: {
    Del(Title, Body, Id) {
      this.$emit("SendDataForDel", { Id: Id, Title: Title, Body: Body });
    },
    Edit(Title, Body, Id) {
      this.$emit("SendDataForEdit", { Id: Id, Title: Title, Body: Body });
    },
  },
  created() {
    this.IsAdmin = BlogServices.IsAdmin(this.$store);
  },
};
</script>

<style scoped>
.BlogListItem {
  background-color: #ffffff;
  padding: 20px;
  margin: 0 0 25px 0;
  border: 1px solid #cccccc;
  height: 375px;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #fff;
  overflow: hidden;
  border-radius: 5px;
  transition: var(--transitionSpeed);
  position: relative;
  background-position: center;
}

.BlogListItem .content {
  overflow: hidden;
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transition: var(--transitionSpeed);
  padding: 30px;
  color: #ecf1ec;
}
.BlogListItem .content p {
  font-size: 24px;
}
.BlogListItem .content h2 {
  font-size: 40px;
  font-weight: 500;
  margin: 0 0 4px;
}

.BlogListItem .top {
  transition: var(--transitionSpeed);
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 10px;
  background: transparent;
}
.BlogListItem:hover .BtnDesign2 {
  box-shadow: 3px 3px 0px rgb(0 0 0 / 40%), 0px 8px 13px rgb(0 0 0 / 10%),
    0px 18px 23px rgb(2 0 0 / 11%);
}
.BtnDesign2 {
  width: fit-content;
  padding: 8px 20px;
  display: flex;
  flex-direction: row !important;
  margin: 20px 10px 0 10px;
  align-items: center;
  font-size: 18px;
  background-color: var(--bs-primary2);
  justify-content: center;
  opacity: 0.9;
  color: #000;
}
.BtnDesign2:hover {
  opacity: 1;
  color: #000;
}
.BtnDesign2 span {
  margin-top: 3px;
}
.BtnDesign2 svg {
  margin-right: 10px;
}

.BlogListItem .Ovrlay {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: var(--transitionSpeed);
}
.BlogListItem:hover .Ovrlay {
  transform: scale(1.01);
}

@media (max-width: 670px) {
  .BlogListItem {
    text-align: center;
  }
  .BlogListItem .content h2 {
    font-size: 27px;
  }
  .BlogListItem .content p {
    font-size: 17px;
  }
}
.Btn {
  padding: 10px 20px;
  margin: 14px 10px;
}
.Btn svg {
  margin-right: 10px;
}
</style>
