<template>
  <div :class="'prompt ' + TooglePopupClass" @click="CheckPosition">
    <div>
      <h3>{{ $store.state.resource.Buttons.DelCourses }}</h3>
      <!-- <input type="text" v-model="Title" /> -->
      <!-- <input type="text" v-model="PlayListUrl" /> -->
      <!-- <textarea v-model="Body"> </textarea> -->
      <div
        class="w-100 d-flex align-content-center justify-content-evenly mt-3"
      >
        <button
          class="btn BtnDesign AnimationBtn btn-danger"
          @click="DelCourse"
        >
          <font-awesome-icon icon="trash" />
          {{ $store.state.resource.Buttons.DelCourses }}
        </button>
        <button class="btn BtnDesignOutline AnimationBtn" @click="Cancel">
          <font-awesome-icon icon="arrow-right" />
          {{ $store.state.resource.ButtonCancel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CourseServices from "../../services/CourseServices.js";
export default {
  name: "DelCourse",
  props: ["Class", "CourseId"],
  data() {
    return {
      Title: "",
      PlayListUrl: "",
      TooglePopupClass: "",
    };
  },
  methods: {
    CheckPosition() {
      let This = this;
      window.onclick = function (event) {
        if (event.target.getAttribute("class") != null) {
          if (event.target.getAttribute("class").search("prompt") == 0) {
            This.$emit("SendClass", "");
          }
        }
      };
    },
    Cancel() {
      this.$emit("SendClass", "");
    },
    DelCourse() {
      CourseServices.DelCourse(this.CourseId).then((Val) => {
        if (Val == true) {
          this.$emit("SendData", "");
        }
      });

      // if (this.Title != "" && this.PlayListUrl != "") {
      //   CourseServices.AddCourse(
      //     this.PlayListUrl,
      //     this.Title,
      //     this.$store.state.auth.user.id
      //   ).then((Val) => {
      //     if (Val == true) {
      //       this.$emit("SendData", "");
      //     }
      //   });
      // }
      // this.$emit("SendTooglePopupClass", "");
    },
  },
  watch: {
    Class(Val) {
      this.TooglePopupClass = Val;
    },
  },
};
</script>
<style scoped>
.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
</style>
