<template>
  <div class="pt-4 pb-5">
    <h1 class="text-center mb-3">الكورسات الأكثر مشاهدة</h1>
    <div
      v-if="Load == false"
      class="spinner-border m-auto d-block text-primary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div
      class="d-flex justify-content-center align-items-center flex-wrap"
      v-if="Load == true"
    >
      <div
        class="card DirectionsForText p-0 mb-3 me-2"
        v-for="(CourseItem, i) in CourseItems"
        :key="'N' + i"
      >
        <img :src="CourseItem.Img" class="card-img-top" height="165" />
        <div class="card-body">
          <h5 class="card-title">
            {{
              CourseItem.title.length >= 100
                ? CourseItem.title.slice(0, 100) + "..."
                : CourseItem.title
            }}
          </h5>
        </div>
        <div class="card-footer text-center">
          <router-link
            :to="'/Course/' + CourseItem.youtubeListId"
            class="btn btn-primary AnimationBtn"
          >
            <span>{{ $store.state.resource.ButtonRead }}</span>
            <font-awesome-icon icon="arrow-right" class="me-3" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseServices from "../../services/CourseServices";
export default {
  name: "MostViewedCourses",
  data() {
    return {
      CourseItems: [],
      Load: false,
    };
  },
  methods: {
    getMultipleRandom(arr, num) {
      const shuffled = arr.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    },
  },
  created() {
    CourseServices.GetList().then((Val) => {
      this.CourseItems = this.getMultipleRandom(Val, 3);
      for (let i = 0; i < this.CourseItems.length; i++) {
        CourseServices.GetYouTubeListImage(
          this.CourseItems[i].youtubeListId
        ).then((Val) => {
          this.CourseItems[i].Img = Val;
          this.Load = true;
        });
      }
    });
  },
};
</script>
<style scoped>
.card {
  width: calc(95% / 3);
}
.card-img-top {
  object-fit: cover;
}
.card-body {
  height: 100px;
}
.card-text {
  font-size: 15px;
  font-weight: 500 !important;
}
@media (max-width: 1100px) {
  .card {
    width: calc(95% / 2);
  }
}
@media (max-width: 782px) {
  .card {
    width: 100%;
  }
}
</style>
