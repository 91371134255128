import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/Home";
import Login from "../components/identity/Login";
import Register from "../components/identity/Register";
import Blog from "../views/Blog.vue";
import View from "../components/Blog/View";
import List from "../components/Blog/List";
import Courses from "../views/Courses";
import AllCourses from "../components/Courses/List";
import WatchCouser from "../components/Courses/WatchCourse";
import redirectToCourseVaild from "../components/Courses/redirectToCourseVaild";
// lazy-loaded
// const Profile = () => import("../components/Profile.vue");
const BoardAdmin = () => import("../components/BoardAdmin.vue");
const BoardModerator = () => import("../components/BoardModerator.vue");
const BoardUser = () => import("../components/BoardUser.vue");
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },

  {
    path: "/Course/",
    component: Courses,
    children: [
      {
        path: "All",
        component: AllCourses,
        props: true,
      },
      {
        path: ":ListId/:VideoId",
        component: WatchCouser,
        props: true,
      },

      {
        path: ":ListId/",
        component: redirectToCourseVaild,
        props: true,
      },
    ],
  },
  {
    path: "/Blog",
    component: Blog,
    children: [
      {
        path: ":id",
        component: View,
        props: true,
      },
      {
        path: "",
        component: List,
      },
    ],
  },
  // {
  //   path: "/profile",
  //   name: "profile",
  //   // lazy-loaded
  //   component: Profile,
  // },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
