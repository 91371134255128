import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "@/assets/Css/overWriteBootstrap.css";
import "@/assets/Css/mainStyle.css";
// import "@/assets/Css/Padding.css";
import "@/assets/Css/style.margins-paddings.css";
import "@/assets/Css/RtlStyle.css";
import "@/assets/Css/Popups.css";
// import "@/assets/Css/LtrStyle.css";
// @import url("../../assets/Css/Popups.css");
import "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle";
import Toaster from "@meforma/vue-toaster";
import "@popperjs/core";
import "bootstrap/dist/js/bootstrap.js";
import { FontAwesomeIcon } from "./plugins/font-awesome";
createApp(App)
  .use(router)
  .use(store)
  .use(Toaster)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
