<template>
  <nav>
    <div class="Left" v-if="currentUser">
      <div class="btn-group">
        <span class="User" data-bs-toggle="dropdown" aria-expanded="false">
          <font-awesome-icon icon="user" />
        </span>
        <ul class="dropdown-menu text-center p-3">
          <li>
            <router-link to="/">{{ UserName }}</router-link>
          </li>
          <li>
            <div class="d-flex align-items-start">
              <span class="User" style="width: 65px">
                <font-awesome-icon icon="user" />
              </span>
              <div dir="rtl">
                <router-link to="/Course/All" class="btn btn-primary w-100 mb-2"
                  >Courses</router-link
                >
                <router-link to="/Blog" class="btn btn-primary w-100"
                  >Posts</router-link
                >
              </div>
            </div>
          </li>
          <hr class="dropdown-divider" />
          <li>
            <button class="btn btn-danger text-white" @click.prevent="logOut">
              <font-awesome-icon icon="sign-out-alt" /> Log out
            </button>
          </li>
        </ul>
      </div>
      <button class="bell meDia Btn">
        <font-awesome-icon icon="bell" />
      </button>
      <button class="th meDia Btn">
        <font-awesome-icon icon="th" />
      </button>
      <button class="video meDia Btn">
        <font-awesome-icon icon="video" />
      </button>
    </div>
    <div class="Left" v-if="!currentUser">
      <router-link
        to="/Login"
        class="btn btn-outline-primary d-flex align-items-center"
      >
        <span class="fs-6 mx-2">تسجيل الدخول</span>
        <font-awesome-icon icon="user-circle" />
      </router-link>
      <button class="meDia Btn">
        <font-awesome-icon icon="ellipsis-v" />
      </button>
      <button class="th meDia Btn">
        <font-awesome-icon icon="th" />
      </button>
    </div>
    <div class="Center">
      <select
        v-model="Lang"
        style="width: 120px"
        class="form-select me-3 d-none"
      >
        <option value="En">English</option>
        <option value="Ar">Arabic</option>
      </select>
      <!-- <button class="microphone">
        <span class="material-icons-outlined"> mic </span>
      </button> -->
      <button class="Search" @click="search">
        <font-awesome-icon icon="search" />
      </button>
      <div :class="'BarSearch ' + Class">
        <input type="text" :placeholder="$store.state.Search" />
        <button>
          <font-awesome-icon icon="search" />
        </button>
      </div>
    </div>
    <div class="Right">
      <router-link to="/">
        <img src="@/assets/images/logo.png" alt="" />
      </router-link>
      <button><font-awesome-icon icon="bars" /></button>
    </div>
  </nav>
</template>
<script>
import UserService from "@/services/user.service";
export default {
  name: "NavBar",
  data() {
    return {
      Lang: "Ar",
      Class: "",
      UserName: "",
    };
  },
  computed: {
    currentUser() {
      // eslint-disable-next-line no-unused-vars
      var User;
      if (this.$store.state.auth.user) {
        User = UserService.GetDataFromToken(this.$store.state.auth.user.token);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.UserName = User.email;
      }
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    search() {
      if (this.Class == "") {
        this.Class = "Show";
      } else {
        this.Class = "";
      }
    },
  },
  // watch: {
  //   Lang(Val) {
  //     if (v == "En") {
  //       this.$store.state.resource = En;
  //     } else if (v == "Ar") {
  //       this.$store.state.resource = Ar;
  //     }
  //   },
  // },
};
</script>
<style scoped>
nav {
  width: 100%;
  height: 70px;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  border-bottom: 2px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

nav .Left {
  width: 26%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
}
nav .Left .User {
  background: var(--bs-primary);
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: var(--radius5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  margin-right: 10px;
}
nav ul li {
  padding: 5px 10px;
}

nav .Left > button.img > div > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  direction: rtl;
  margin: 10px 0;
}

nav .Left > button.img > div > div img {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

nav .Left > button.img > div > div h6 {
  font-size: 20px;
}

nav .Left > button.img > div span {
  background-color: var(--ColorMain);
  color: #fff;
  padding: 8px;
  width: 95%;
  margin: 0px auto;
  border-radius: var(--radius5);
}

nav .Left .search {
  display: none;
}

nav .Left > button,
nav > button {
  width: 40px;
  height: 40px;
  font-size: 18px;
  border: 0;
  background: transparent;
  color: var(--Search2);
  margin-right: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

nav .Center {
  width: 50%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav .Center .Search {
  display: none;
}
nav .Center .BarSearch {
  width: 80%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}
nav .Center .BarSearch.Show {
  display: flex !important;
  position: absolute;
  top: 70px;
  width: 100%;
  left: 0;
  background: transparent;
}
nav .Center .search {
  transform: scale(0);
}

nav .Center .BarSearch input {
  width: calc(100% - 80px);
  height: 95%;
  padding: 0 10px;
  transition: var(--transitionSpeed);
  border: 1px solid #ccc;
  background-color: var(--BackGroundColor2);
  color: var(--Search2);
  opacity: 0.9;
  outline: 0;
  font-size: 17px;
  font-weight: 900;
  font-family: var(--Font);
  font-weight: 500;
}

nav .Center .BarSearch button {
  width: 80px;
  height: 95%;
  font-size: 20px;
  cursor: pointer;
  transition: var(--transitionSpeed);
  background-color: var(--BackGroundColor2);
  color: var(--Search2);
  border: 1px solid #ccc;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

nav .Center .BarSearch input:focus + button {
  opacity: 1;
}

nav .Center > button {
  width: 50px;
  height: 45px;
  font-size: 20px;
  border: 0;
  background: var(--Search1);
  color: var(--Search2);
  margin-right: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/*  nav .Right */

nav .Right {
  width: 20%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
}

nav .Right a {
  width: 69px;
  height: 55px;
  display: inline-block;
  position: relative;
  right: 5px;
  top: 1px;
}

nav .Right a img {
  width: 100%;
  height: 100%;
}

nav .Right button {
  width: 80px;
  height: 100%;
  background: #fff;
  color: var(--Color);
  font-size: 20px;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Right button {
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 505px) {
  .meDia {
    display: none !important;
  }
  nav .Left {
    width: 50% !important;
    padding-left: 10px;
    margin-right: 5px;
  }
  nav .Center {
    width: 10% !important;
  }

  nav .Right {
    width: 35% !important;
  }
  nav .Center .Search {
    display: block;
  }
  nav .Center .BarSearch {
    display: none;
  }
}
</style>
