<template>
  <div
    class="Container_WebSite d-flex align-items-center justify-content-center"
  >
    <div class="spinner-border m-auto text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import CourseServices from "../../services/CourseServices.js";
export default {
  name: "AllCousers",
  props: ["ListId"],
  data() {
    return {
      IsAdmin: false,
    };
  },
  created() {
    if (this.$store.state.auth.status.loggedIn == true) {
      CourseServices.GetVideosCourse(
        this.ListId,
        this.$store.state.auth.user.id
      ).then((Val) => {
        var Data = Val.VidoesWatched.slice(Val.VidoesWatched.length - 1)[0];
        this.Url_Go = "/" + Data.snippet.resourceId.videoId;
        this.Show = true;
        this.$router.push(`/Course/${this.ListId}${this.Url_Go}`);
      });
    } else {
      this.$router.push(`/Login/`);
    }
  },
};
</script>
<style scoped>
.Container_WebSite {
  height: 80vh;
}
</style>
