<template>
  <div :class="'prompt ' + TogglePopupClass" @click="CheckPosition">
    <div>
      <h3>{{ question }}</h3>
      <input type="text" v-model="Answer" />
      <div
        class="w-100 d-flex align-content-center justify-content-evenly mt-3"
      >
        <button class="btn btn-primary AnimationBtn" @click="AddAnswer">
          {{ $store.state.resource.Video.AddAnswer }}
          <font-awesome-icon icon="plus" class="ms-2 me-2" />
        </button>
        <button class="btn btn-outline-primary AnimationBtn" @click="Cancel">
          {{ $store.state.resource.ButtonCancel }}
          <font-awesome-icon icon="arrow-right" class="ms-2 me-2" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionsServices from "../../services/QuestionsServices.js";
import CourseServices from "../../services/CourseServices";
export default {
  name: "AddAnswer",
  props: ["Class", "QuestionID", "VideoId", "question", "ListId"],
  data() {
    return {
      TogglePopupClass: "",
      Answer: "",
    };
  },
  methods: {
    CheckPosition() {
      let This = this;
      window.onclick = function (event) {
        if (event.target.getAttribute("class") != null) {
          if (event.target.getAttribute("class").search("prompt") == 0) {
            This.$emit("SendClass", "");
          }
        }
      };
    },
    Cancel() {
      this.$emit("SendClass", "");
    },
    AddAnswer() {
      this.$emit("SendClass", "");
      QuestionsServices.Add_Answer(
        this.QuestionID,
        this.$store.state.auth.user.id,
        this.VideoId,
        this.Answer
      );
      CourseServices.GetVideosCourse(
        this.ListId,
        this.$store.state.auth.user.id
      ).then((Val) => {
        this.$emit("SendData", Val);
        let NextVideoId = Val.VidoesWatched.slice(
          Val.VidoesWatched.length - 1
        )[0].snippet.resourceId.videoId;
        this.$router.push(`/Course/${this.ListId}/${NextVideoId}`);
      });
    },
  },
  watch: {
    Class(Val) {
      this.TogglePopupClass = Val;
    },
  },
};
</script>
