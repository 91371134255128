<template>
  <div class="Grid" v-if="$store.state.auth.status.loggedIn == true">
    <!-- Popups -->
    <AddQuestion
      :VideoId="recentVideoId"
      :Class="ClassAddQuestion"
      :ListId="ListId"
      @SendClass="ClassAddQuestion = $event"
    />

    <DelQuestion
      :VideoId="VideoIdToDel"
      :Class="ClassDelQuestion"
      @SendClass="ClassDelQuestion = $event"
    />
    <EditQuestion
      :VideoId="recentVideoId"
      :Class="ClassEditQuestion"
      :ListId="ListId"
      @SendClass="ClassEditQuestion = $event"
    />
    <!-- End Popups -->
    <div class="Left padding-20">
      <div class="heigth">
        <h6 class="DirectionsForText text-danger">
          بعد مشاهدة الحلقة سيظهر لك سؤال بعد اجابته ستنتقل للحلقة التالية
        </h6>
      </div>
      <div class="Videos justify-content-center">
        <div
          v-if="Vidoes == ''"
          class="spinner-border m-auto d-block text-primary"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <div v-if="!IsAdmin">
          <router-link
            :to="'/Course/' + ListId + '/' + Video.snippet.resourceId.videoId"
            v-for="(Video, i) in VidoesWatched"
            :key="'A,' + i"
            :title="Video.snippet.title"
            class="BTNWatch Vaild"
          >
            <h5>
              {{ Video.snippet.title }}
            </h5>
            <font-awesome-icon icon="check-circle" />
          </router-link>
          <router-link
            :to="'/Course/' + ListId + '/' + VideoId"
            v-for="(Divs, i) in Vidoes"
            :key="'B,' + i"
            :title="Divs.snippet.title"
            class="BTNWatch inVaild opacity-7"
            ><h5 class="opacity-5">
              {{ Divs.snippet.title }}
            </h5>
            <font-awesome-icon icon="lock" />
          </router-link>
        </div>
        <div v-if="IsAdmin">
          <div
            :to="'/Course/' + ListId + '/' + Vidoe.snippet.resourceId.videoId"
            v-for="(Vidoe, i) in Vidoes"
            :key="'B,' + i"
            :title="Vidoe.snippet.title"
            class="BTNWatch OtherVaild opacity-7"
          >
            <router-link
              class="opacity-5"
              :to="'/Course/' + ListId + '/' + Vidoe.snippet.resourceId.videoId"
            >
              {{ Vidoe.snippet.title }}
            </router-link>
            <div>
              <button
                @click="AddQuestion(Vidoe.snippet.resourceId.videoId)"
                class="btn btn-primary AnimationBtn"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="اضافةالسؤال"
              >
                <font-awesome-icon icon="plus" />
              </button>
              <button
                @click="EditQuestion(Vidoe.snippet.resourceId.videoId)"
                class="btn btn-warning AnimationBtn"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="تعديل السؤال"
              >
                <font-awesome-icon icon="edit" />
              </button>
              <button
                @click="DelQuestion(Vidoe.snippet.resourceId.videoId)"
                class="btn btn-danger AnimationBtn"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="حذف السؤال"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Right padding-20">
      <div class="heigth">
        <div
          v-if="Load"
          class="spinner-border mx-auto mb-2 d-block text-primary"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <div
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
          dir="rtl"
          v-if="!IsAdmin && questionBool == false"
        >
          <strong> انت لم تشاهد هذا الفيديو</strong>
        </div>
        <div
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
          dir="rtl"
          v-if="question == undefined"
        >
          <strong>لايوجد سؤال علي هذا الفيديو </strong>
        </div>
        <button
          @click="ShowPopupAddAnswer"
          v-if="!IsAdmin && questionBool == true && !(question == undefined)"
          :class="'btn btn-danger mt-2 mb-2 ms-auto ' + questionClass"
        >
          {{ $store.state.resource.Video.BtnAnswer }}
        </button>
      </div>
      <AddAnswer
        v-if="!IsAdmin && questionBool == true"
        :Class="Class"
        :QuestionID="QuestionID"
        :VideoId="NewVideoId"
        :question="question"
        @SendClass="Class = $event"
        @SendData="SendData"
        :ListId="ListId"
      />
      <div id="InnderVideo"></div>
      <hr />
      <h3>{{ TitleVideo }}</h3>
      <p v-if="IsAdmin" style="white-space: pre-line; word-break: break-word">
        {{ description }}
      </p>
      <div v-if="!IsAdmin && description != ''" class="description p-4">
        <p style="white-space: pre-line; word-break: break-word">
          {{ description2 }}
        </p>
        <button @click="Change" v-if="description.length >= 100">
          {{ Button }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CourseServices from "../../services/CourseServices";
import AddAnswer from "../Questions/AddAnswer";
import AddQuestion from "../Questions/AddQuestion";
import DelQuestion from "../Questions/DelQuestion";
import EditQuestion from "../Questions/EditQuestion";
import $ from "jquery";

export default {
  name: "WatchCourse",
  props: ["ListId", "VideoId"],
  components: {
    AddAnswer,
    AddQuestion,
    DelQuestion,
    EditQuestion,
  },
  data() {
    return {
      VidoesWatched: "",
      Vidoes: "",
      TitleVideo: "",
      description: "",
      description2: "",
      IsAdmin: false,
      question: "",
      questionClass: "Hide",
      DomHtml: "",
      ClassPopup: "Hide",
      Button: "Show Text",
      Class: "",
      ClassAddQuestion: "",
      ClassEditQuestion: "",
      ClassDelQuestion: "",
      QuestionID: "",
      NewVideoId: "",
      recentVideoId: "",
      Load: false,
      questionBool: false,
      VideoIdToDel: "",
    };
  },
  methods: {
    Change() {
      if (this.Button == "Show Text") {
        this.Button = "Hidden Text";
        this.description2 = this.description.slice(0, this.description.length);
      } else if (this.Button == "Hidden Text") {
        this.Button = "Show Text";
        this.description2 = this.description.slice(0, 100) + "...";
      }
    },
    load(ID) {
      let Id = ID;
      let player;
      let THIS = this;
      document.getElementById("InnderVideo").innerHTML =
        "<div id='video'></div>";
      function loadVideo() {
        window.YT.ready(function () {
          player = new window.YT.Player("video", {
            height: "390",
            width: "640",
            videoId: Id,
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange,
            },
          });
        });
        function onPlayerReady() {
          player.pauseVideo();
        }
        function onPlayerStateChange(event) {
          if (event.data === 0) {
            if (!THIS.IsAdmin) {
              CourseServices.GetVideosCourse(
                THIS.ListId,
                THIS.$store.state.auth.user.id
              ).then((Val) => {
                THIS.Vidoes = Val.Vidoes;
                THIS.VidoesWatched = Val.VidoesWatched;
              });
              CourseServices.GetVideoswatched(
                THIS.$store.state.auth.user.id,
                THIS.ListId
              );
              THIS.Class = "";
              THIS.Load = true;
              THIS.Load2();
              THIS.questionBool = "s";
              CourseServices.PostWatched(
                THIS.$store.state.auth.user.id,
                THIS.ListId,
                THIS.VideoId
              );
            }
          }
        }
      }
      $(document).ready(function () {
        $.getScript("https://www.youtube.com/iframe_api", function () {
          loadVideo();
        });
      });
    },
    Load2() {
      this.IsAdmin = CourseServices.IsAdmin(this.$store);
      this.questionBool = false;
      if (this.IsAdmin) {
        CourseServices.GetVideosCourse(
          this.ListId,
          this.$store.state.auth.user.id,
          true
        ).then((Val) => {
          this.Vidoes = Val.Vidoes;
        });
      } else {
        CourseServices.GetVideosCourse(
          this.ListId,
          this.$store.state.auth.user.id,
          false
        ).then((Val) => {
          this.VidoesWatched = Val.VidoesWatched;
          this.Vidoes = Val.Vidoes;
        });
      }
      CourseServices.LoadOrWatchFuntion(this.VideoId).then((Val) => {
        this.description = Val.description;
        this.description2 = Val.description2;
        this.TitleVideo = Val.title;
        document.title = `صناع التاريخ | الكورسات | ${this.TitleVideo}`;
        CourseServices.GetVideoswatched(
          this.$store.state.auth.user.id,
          this.ListId
        ).then((Val2) => {
          for (let i = 0; i < Val2.length; i++) {
            if (Val2[i].videoId == this.VideoId) {
              this.question = Val.question;
              this.QuestionID = Val.QuestionID;
              if (this.question != "") {
                this.questionClass = "Show";
                this.questionBool = true;
                this.Load = false;
              }
            }
          }
        });
      });
    },
    ShowPopupAddAnswer() {
      this.Class = "active";
    },
    SendData(Val) {
      this.VidoesWatched = Val.VidoesWatched;
      this.Vidoes = Val.Vidoes;
    },
    AddQuestion(VideoId) {
      this.ClassAddQuestion = "active";
      this.recentVideoId = VideoId;
    },
    EditQuestion(VideoId) {
      this.ClassEditQuestion = "active";
      this.recentVideoId = VideoId;
    },
    DelQuestion(VideoId) {
      this.ClassDelQuestion = "active";
      this.VideoIdToDel = VideoId;
    },
  },
  mounted() {
    if (this.$store.state.auth.status.loggedIn == false) {
      this.$router.push("/Login");
    } else {
      this.Load2();
      this.load(this.VideoId);
      this.NewVideoId = this.VideoId;
    }
  },
  watch: {
    VideoId(Val) {
      this.question = "";
      this.load(Val);
      this.Load2();
      this.NewVideoId = Val;
      CourseServices.LoadOrWatchFuntion(Val).then((Val) => {
        this.description = Val.description2;
        this.TitleVideo = Val.title;
        document.title = `صناع التاريخ | الكورسات | ${this.TitleVideo}`;
      });
    },
  },
};
</script>

<style scoped>
.Grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.Grid .Left {
  width: 35%;
}
.Grid .Right {
  width: 64%;
}
.Grid .Right .description {
  font-size: 15px;
  direction: rtl;
}

.Grid .Right .description p {
  height: auto;
  max-height: 160px;
  overflow: auto !important;
}

.Grid .Right .description button {
  background-color: transparent;
  color: var(--ColorMain);
  border: 0;
}
.Videos {
  height: 450px;
  overflow: auto;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.Vaild {
  border: 3px solid var(--bs-primary) !important;
  color: var(--bs-primary);
}
.Hide {
  display: none;
}
.Show {
  display: inline-block;
}

.inVaild {
  border: 3px solid #000 !important;
  opacity: 0.8;
}
.OtherVaild {
  border: 3px solid var(--bs-primary) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.OtherVaild > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
}
.OtherVaild button {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin: 0 4px;
}
.OtherVaild button svg {
  width: 90%;
  height: 90%;
}
.BTNWatch {
  display: flex;
  padding: 10px;
  margin-bottom: 4px;
  direction: rtl;
  color: #040404;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
}
.BTNWatch svg {
  width: 50px;
}
.BTNWatch.Vaild svg {
  color: var(--bs-primary);
}
.BTNWatch.inVaild svg {
  opacity: 0.5;
}
.BTNWatch h5 {
  font-size: 17px !important;
  margin: 3px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 150px);
  white-space: nowrap;
  color: #000 !important;
}
.BTNWatch a {
  font-size: 17px !important;
  margin: 3px 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: calc(100% - 150px);
  white-space: nowrap;
  color: #000 !important;
}
div.heigth {
  height: 70px;
}
@media (max-width: 1000px) {
  .Grid {
    flex-direction: column-reverse;
  }
  .Grid .Left {
    width: 100%;
  }
  .Grid .Right {
    width: 100%;
  }
}
</style>
