<template>
  <div class="Slider mt-4">
    <h1 class="text-center mb-4">
      المتدربين الحاصلين علي شهادة الانتهاء من الكورس
    </h1>
    <div
      id="carouselExampleCaptions"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>

        <button
          v-for="(Image, i) in Images.slice(2, -1)"
          :key="'A' + i"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          :data-bs-slide-to="i + 1"
          :aria-label="'Slide ' + Number(i + 2)"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            :src="require(`@/assets/images/Website/${Images[0]}`)"
            class="d-block w-100"
          />
        </div>
        <div
          v-for="(Image, i) in Images.slice(2, -1)"
          :key="'A' + i"
          class="carousel-item"
        >
          <img
            :src="require(`@/assets/images/Website/${Image}`)"
            class="d-block w-100"
          />
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSlider",
  data() {
    return {
      Images: [
        "1.jpeg",
        "2.jpg",
        "3.jpg",
        "4.jpeg",
        "5.jpg",
        "6.jpg",
        "7.jpg",
        "8.jpg",
        "9.jpg",
      ],
    };
  },
};
</script>
<style scoped>
div.Slider {
  width: 95%;
  margin: 0 auto;
}
#carouselExampleCaptions {
  width: 100%;
  margin: 0 auto;
}
#carouselExampleCaptions img {
  height: 400px !important;
  object-fit: cover;
}
</style>
