<template>
  <main>
    <div>
      <h1>تسجيل الدخول</h1>
      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group Input_DIv">
          <label for="username">البريد الألكتروني</label>
          <Field name="username" type="text" class="form-control" />
          <ErrorMessage name="username" class="text-danger error-feedback" />
        </div>
        <div class="form-group Input_DIv">
          <label for="password">كلمة المرور</label>
          <Field name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="text-danger error-feedback" />
        </div>
        <router-link to="/Register" class="d-block w-100 mb-3 text-center"
          >انشاء حساب جديد</router-link
        >
        <div class="form-group Input_DIv">
          <button
            class="btn btn-primary d-block w-75 mx-auto AnimationBtn"
            :disabled="loading"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>تسجيل الدخول</span>
          </button>
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </main>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("البريد الألكتروني مطلوب"),
      password: yup.string().required("كلمة المرور مطلوب"),
    });
    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
    if (this.$store.state.Alert != "") {
      this.$toast.success(this.$store.state.Alert, {
        position: "top",
      });
      setTimeout(() => {
        this.$store.state.Alert = "";
      }, 10);
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push(this.$store.state.History);
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
<style scoped>
main {
  direction: rtl;
  margin-top: 70px;
  padding: 20px;
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;
}
main > div {
  height: auto;
  background: #fff;
  border-radius: var(--radius20);
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  position: relative;
}
main > div h1 {
  margin-bottom: 20px !important;
}

main > div form {
  width: 90%;
  display: flex;
  flex-direction: column;
}
main form .Input_DIv {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

main form .Input_DIv span {
  font-size: 20px;
  font-weight: 500;
}
main form .Input_DIv input {
  width: 100%;
  height: 50px;
  border-radius: var(--radius5);
  border: 2px solid #ccc;
  outline: 0;
  padding: 20px 18px;
}
main form a {
  font-size: 23px;
  text-decoration: underline !important;
}
</style>
