<template>
  <div class="Container_WebSite">
    <div class="Grid">
      <div class="Left padding-20">
        <LeftBlogBar />
      </div>
      <div class="Right padding-20">
        <button
          v-if="IsAdmin"
          class="btn btn-primary mt-3 mb-3 AnimationBtn"
          @click="AddClassForEditAddCourses"
        >
          <font-awesome-icon icon="plus" class="mx-1" />
          {{ $store.state.resource.Buttons.AddCourses }}
        </button>
        <AddCourse
          :Class="ClassAddBlogItem"
          @SendClass="ClassAddBlogItem = $event"
          v-if="IsAdmin"
          @SendData="Data"
        />
        <EditCourse
          :Class="ClassEditBlogItem"
          @SendClass="ClassEditBlogItem = $event"
          v-if="IsAdmin"
          @SendData="Data"
          :Title="TitleToEdit"
          :PlayListUrl="ListId"
          :CourseId="CourseId"
        />
        <DelCourse
          :Class="ClassDelBlogItem"
          :CourseId="CourseId"
          @SendClass="ClassDelBlogItem = $event"
          v-if="IsAdmin"
          @SendData="Data"
        />
        <CoursesListItem
          v-for="(Course, i) in Courses"
          :DataCourse="Course"
          :key="'A' + i"
          @AddClassForEdit="AddClassForEdit"
          @AddClassForDel="AddClassForDel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CourseServices from "../../services/CourseServices.js";
import CoursesListItem from "./ListItem";
import AddCourse from "./AddCourse";
import EditCourse from "./EditCourse";
import DelCourse from "./DelCourse";
export default {
  name: "AllCousers",
  components: {
    CoursesListItem,
    AddCourse,
    EditCourse,
    DelCourse,
  },
  data() {
    return {
      Courses: [],
      IsAdmin: false,
      ClassAddBlogItem: "",
      ClassDelBlogItem: "",
      ClassEditBlogItem: "",
      CourseId: "",
      ListId: "",
      TitleToEdit: "",
    };
  },
  created() {
    CourseServices.GetList().then((Val) => {
      this.Courses = Val;
    });
    this.IsAdmin = CourseServices.IsAdmin(this.$store);
  },
  methods: {
    AddClassForEditAddCourses() {
      this.ClassAddBlogItem = "active";
    },
    AddClassForEdit(Value) {
      this.ClassEditBlogItem = "active";
      this.ListId = Value.PlayListUrl;
      this.TitleToEdit = Value.Title;
      this.CourseId = Value.PlayListUrl;
    },
    AddClassForDel(Value) {
      this.ClassDelBlogItem = "active";
      this.CourseId = Value;
    },
    Data() {
      this.Courses = "";
      this.ClassAddBlogItem = "";
      this.ClassEditBlogItem = "";
      this.ClassDelBlogItem = "";
      CourseServices.GetList().then((Val) => {
        this.Courses = Val;
      });
    },
  },
};
</script>
<style scoped>
.Grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.Grid .Left {
  width: 35%;
}
.Grid .Right {
  width: 64%;
  display: flex;
  flex-direction: column;
}

/* This is Media */
@media (max-width: 1000px) {
  .Grid {
    flex-direction: column;
  }
  .Grid .Left {
    width: 100%;
  }
  .Grid .Right {
    width: 100%;
  }
}
</style>
