<template>
  <div>
    <router-link
      to="/Blog"
      tag="button"
      class="btn PositionCenter BtnDesign mb-4 AnimationBtn"
    >
      <span>{{ $store.state.resource.ButtonBack }}</span>
      <font-awesome-icon icon="arrow-left" class="me-2" />
    </router-link>
    <div
      v-if="Load == false"
      class="spinner-border m-auto d-block text-primary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <article v-if="Load == true">
      <div>
        <img
          :src="require(`@/assets/images/Image.jpeg`)"
          width="100%"
          class="mb-3"
        />
      </div>
      <div class="content">
        <h2>
          {{ BlogItem.title }}
        </h2>
        <!-- <h5 class="p-2 bg-Website text-white rounded-1">
          الكاتب:
          {{ BlogItem.auther }}
        </h5> -->
        <p>
          {{ BlogItem.body }}
        </p>
      </div>
    </article>
  </div>
</template>

<script>
import BlogServices from "../../services/BlogServices.js";
export default {
  name: "ViewPost",
  props: ["id"],
  data() {
    return {
      BlogItem: "",
      Load: false,
    };
  },
  created() {
    if (this.$store.state.auth.status.loggedIn == true) {
      BlogServices.WhoRead(this.$store.state.auth.user.id, this.id);
    }
    this.$store.state.History = `/Blog/${this.id}`;
    BlogServices.GetBlogItem(this.id).then((Val) => {
      this.BlogItem = Val;
      this.Load = true;
      document.title = `صناع التاريخ | المدونة |  ${this.BlogItem.title}`;
    });
  },
  watch: {
    id(v) {
      this.Load = false;
      if (this.$store.state.auth.status.loggedIn == true) {
        BlogServices.WhoRead(this.$store.state.auth.user.id, v);
      }
      this.$store.state.History = `/Blog/${v}`;
      BlogServices.GetBlogItem(v).then((Val) => {
        this.BlogItem = Val;
        this.Load = true;
        document.title = `صناع التاريخ | المدونة |  ${this.BlogItem.title}`;
      });
    },
  },
};
</script>
<style scoped>
article {
  width: 100%;
  margin: 27px auto 0;
  display: block;
}
article img {
  width: 100%;
  object-fit: cover;
  height: 400px;
}
article {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #cccccc;
}
article .content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
article .content p {
  font-size: 20px;
  white-space: pre-line;
}
</style>
