<template>
  <div :class="'prompt ' + TooglePopupClass" @click="CheckPosition">
    <div>
      <h3>{{ $store.state.resource.Buttons.AddBlogItem }}</h3>
      <input
        type="text"
        v-model="Title"
        :placeholder="$store.state.resource.Buttons.Title"
      />
      <textarea
        v-model="Body"
        :placeholder="$store.state.resource.Buttons.describe"
      >
      </textarea>
      <div
        class="w-100 d-flex align-content-center justify-content-evenly mt-3"
      >
        <button class="btn BtnDesign AnimationBtn" @click="AddBlogItem">
          <font-awesome-icon icon="plus" />
          {{ $store.state.resource.ButtonAdd }}
        </button>
        <button class="btn BtnDesignOutline AnimationBtn" @click="Cancel">
          <font-awesome-icon icon="arrow-right" />
          {{ $store.state.resource.ButtonCancel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BlogServices from "../../services/BlogServices.js";
export default {
  name: "AddCourse",
  props: ["Class"],
  data() {
    return {
      Title: "",
      Body: "",
      TooglePopupClass: "",
    };
  },
  methods: {
    CheckPosition() {
      let This = this;
      window.onclick = function (event) {
        if (event.target.getAttribute("class") != null) {
          if (event.target.getAttribute("class").search("prompt") == 0) {
            This.$emit("SendClass", "");
          }
        }
      };
    },
    Cancel() {
      this.$emit("SendClass", "");
      this.Title = "";
      this.Body = "";
    },
    AddBlogItem() {
      if (this.Title != "" && this.Body != "") {
        BlogServices.AddBlogItem(
          this.Title,
          this.Body,
          this.$store.state.auth.user.id
        ).then((Val) => {
          if (Val) {
            this.$emit("SendData", "");
            this.$emit("SendClass", "");
            this.Title = "";
            this.Body = "";
          }
        });
      }
    },
  },

  watch: {
    Class(Val) {
      this.TooglePopupClass = Val;
    },
  },
};
</script>
