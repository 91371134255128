<template>
  <div class="Container_WebSite pt-3">
    <h1 class="text-center mb-5 mt-2">{{ $store.state.resource.title }}</h1>
    <TheEightValue />
    <About />
    <Video />
    <HomeSilder />
    <MostViewedCourses />
    <MostViewedBlogs />
  </div>
</template>
<script>
import UserService from "../services/user.service";
import TheEightValue from "./Layout/TheEightValue";
import About from "./Layout/About";
import Video from "./Layout/Video";
import HomeSilder from "./Layout/HomeSlider";
import MostViewedCourses from "./Layout/MostViewedCourses";
import MostViewedBlogs from "./Layout/MostViewedBlogs";

export default {
  name: "Home",
  components: {
    TheEightValue,
    About,
    Video,
    HomeSilder,
    MostViewedCourses,
    MostViewedBlogs,
  },
  data() {
    return {
      UserName: "",
    };
  },
  mounted() {
    document.title = "صناع التاريخ";
    this.$store.state.History = `/`;
    if (this.$store.state.auth.user != null) {
      let User = UserService.GetDataFromToken(
        this.$store.state.auth.user.token
      );
      this.UserName = User.email;
    }

    // UserService.getPublicContent().then(
    //   (response) => {
    //     this.content = response.data;
    //   },
    //   (error) => {
    //     this.content =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();
    //   }
    // );
  },
};
</script>
