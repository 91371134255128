<template>
  <div :class="'prompt ' + TooglePopupClass" @click="CheckPosition">
    <div>
      <h3>{{ $store.state.resource.Video.AddQuestion }}</h3>
      <h6 class="text-white bg-danger p-2 BorderRudius mt-3" v-if="Alert == ''">
        يوجد سؤال علي هذا الفيديو
      </h6>
      <div
        v-if="!Show"
        class="spinner-border m-auto d-block text-primary"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <input type="text" v-model="Question" v-if="Show && Alert != ''" />
      <div
        v-if="Show"
        class="w-100 d-flex align-content-center justify-content-evenly mt-3"
      >
        <button
          class="btn btn-primary AnimationBtn"
          v-if="Alert != ''"
          @click="AddQuestion"
        >
          {{ $store.state.resource.Video.AddQuestion }}
          <font-awesome-icon icon="plus" class="ms-2 me-2" />
        </button>
        <button class="btn btn-outline-primary AnimationBtn" @click="Cancel">
          {{ $store.state.resource.ButtonCancel }}
          <font-awesome-icon icon="arrow-right" class="ms-2 me-2" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionsServices from "../../services/QuestionsServices.js";
export default {
  name: "AddQuestion",
  props: ["Class", "VideoId", "ListId"],
  data() {
    return {
      TooglePopupClass: "",
      Question: "",
      Show: false,
    };
  },
  methods: {
    CheckPosition() {
      let This = this;
      window.onclick = function (event) {
        if (event.target.getAttribute("class") != null) {
          if (event.target.getAttribute("class").search("prompt") == 0) {
            This.Question = "";
            This.$emit("SendClass", "");
          }
        }
      };
    },
    Cancel() {
      this.Question = "";
      this.$emit("SendClass", "");
    },
    AddQuestion() {
      if (this.Question != "") {
        this.$emit("SendClass", "");
        QuestionsServices.AddQuestion(
          this.ListId,
          this.VideoId,
          this.Question,
          this.$store.state.auth.user.id
        );
      }
      this.$emit("SendClass", "");
      this.Question = "";
    },
  },
  watch: {
    Class(Val) {
      this.Show = false;
      this.TooglePopupClass = Val;
      this.Alert = "";
      this.Question = "";
      QuestionsServices.GetQuestion(this.VideoId).then((Val) => {
        if (Val != "No Qustion") {
          this.Question = "";
          this.Question = Val.content;
          this.Show = true;
          this.Alert = "";
        }
        if (Val == "No Qustion") {
          this.Question = "";
          this.Alert = "لاتوجد اسئلة علي هذا الفيديو";
          this.Show = true;
        }
      });
    },
  },
};
</script>
