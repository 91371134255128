import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faSearch,
  faArrowLeft,
  faArrowRight,
  faTrash,
  faEdit,
  faPlus,
  faLock,
  faCheckCircle,
  faBars,
  faBlog,
  faGraduationCap,
  faUserCircle,
  faBell,
  faTh,
  faVideo,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faFacebook,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";
library.add(
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faSearch,
  faArrowLeft,
  faArrowRight,
  faTrash,
  faEdit,
  faPlus,
  faLock,
  faCheckCircle,
  faBars,
  faBlog,
  faGraduationCap,
  faUserCircle,
  faBell,
  faTh,
  faVideo,
  faEllipsisV,
  faYoutube,
  faFacebook,
  faWhatsappSquare
);
export { FontAwesomeIcon };
