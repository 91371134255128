<template>
  <div :class="'prompt ' + TooglePopupClass" @click="CheckPosition">
    <div>
      <img
        :src="require('@/assets/images/Image.jpeg')"
        width="100%"
        class="mb-3"
      />
      <input type="text" :value="Title" disabled />
      <textarea :value="Body" disabled></textarea>
      <div
        class="w-100 d-flex align-content-center justify-content-evenly mt-3"
      >
        <button class="btn btn-danger AnimationBtn" @click="Del">
          {{ $store.state.resource.ButtonDel }}
          <font-awesome-icon icon="trash" />
        </button>
        <button class="btn btn-outline-primary AnimationBtn" @click="Cancel">
          {{ $store.state.resource.ButtonCancel }}
          <font-awesome-icon icon="arrow-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BlogServices from "../../services/BlogServices.js";

export default {
  name: "DelPopup",
  props: ["Id", "Title", "Body", "Class"],
  data() {
    return {
      TooglePopupClass: "",
    };
  },
  methods: {
    CheckPosition() {
      let This = this;
      window.onclick = function (event) {
        if (event.target.getAttribute("class") != null) {
          if (event.target.getAttribute("class").search("prompt") == 0) {
            This.$emit("SendClass", "");
          }
        }
      };
    },
    Cancel() {
      this.$emit("SendClass", "");
    },
    Del() {
      BlogServices.DelBlogItem(this.Id).then((Val) => {
        if (Val == true) {
          this.$emit("SendClass", "");
          this.$emit("SendData", "");
        }
      });
    },
  },
  watch: {
    Class(Val) {
      this.TooglePopupClass = Val;
    },
  },
};
</script>
