<template>
  <div :class="'prompt ' + TooglePopupClass" @click="CheckPosition">
    <div>
      <h3>{{ $store.state.resource.Video.DelQuestion }}</h3>
      <h6 class="text-white bg-danger p-2 BorderRudius mt-3" v-if="Alert != ''">
        {{ Alert }}
      </h6>
      <div
        v-if="!Show"
        class="spinner-border m-auto d-block text-primary"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <input
        type="text"
        :value="Question"
        v-if="Show && Alert == ''"
        disabled
      />
      <div
        v-if="Show"
        class="w-100 d-flex align-content-center justify-content-evenly mt-3"
      >
        <button
          class="btn btn-danger AnimationBtn"
          v-if="Alert == ''"
          @click="DelQuestion"
        >
          {{ $store.state.resource.Video.DelQuestion }}
          <font-awesome-icon icon="plus" class="ms-2 me-2" />
        </button>
        <button class="btn btn-outline-primary AnimationBtn" @click="Cancel">
          {{ $store.state.resource.ButtonCancel }}
          <font-awesome-icon icon="arrow-right" class="ms-2 me-2" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionsServices from "../../services/QuestionsServices.js";
import CourseServices from "../../services/CourseServices";
export default {
  name: "DelQuestion",
  props: ["Class", "VideoId"],
  data() {
    return {
      TooglePopupClass: "",
      Question: "",
      QuestionId: "",
      Show: false,
      HideButtonDel: false,
      Alert: "",
    };
  },
  methods: {
    CheckPosition() {
      let This = this;
      window.onclick = function (event) {
        if (event.target.getAttribute("class") != null) {
          if (event.target.getAttribute("class").search("prompt") == 0) {
            This.Question = "";

            This.$emit("SendClass", "");
          }
        }
      };
    },
    Cancel() {
      this.Question = "";
      this.$emit("SendClass", "");
    },
    DelQuestion() {
      if (this.QuestionId != "") {
        this.$emit("SendClass", "");
        QuestionsServices.delQuestion(this.QuestionId);
      }
      this.$emit("SendClass", "");
      this.Question = "";
    },
  },
  watch: {
    VideoId(Val) {
      this.Question = "";
      this.NewVideoId = Val;
      this.Show = false;
    },
    Class(Val) {
      this.Show = false;
      this.TooglePopupClass = Val;
      this.NewQuestionId = this.QuestionId;
      this.Alert = "";
      QuestionsServices.GetQuestion(this.NewVideoId).then((Val) => {
        if (Val != "No Qustion") {
          this.Question = "";
          this.Question = Val.content;
          CourseServices.LoadOrWatchFuntion(this.NewVideoId).then((Val2) => {
            this.QuestionId = Val2.QuestionID;
            this.Show = true;
            this.Alert = "";
          });
        }
        if (Val == "No Qustion") {
          this.Alert = "لاتوجد اسئلة علي هذا الفيديو";
          this.Show = true;
        }
      });
    },
  },
};
</script>
