<template>
  <div class="Container_WebSite">
    <div class="Grid">
      <div class="Left padding-20">
        <LeftBlogBar />
      </div>
      <div class="Right padding-20">
        <!-- <List /> -->
        <router-view :class="Class" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftBlogBar from "@/components/Blog/LeftBlogBar";
export default {
  name: "Blog",
  components: { LeftBlogBar },
  data() {
    return {
      Class: "",
    };
  },
  created() {
    this.$store.state.History = "/Blog/";
  },
};
</script>

<style scoped>
.Grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.Grid .Left {
  width: 35%;
}
.Grid .Right {
  width: 64%;
}
@media (max-width: 1000px) {
  .Grid {
    flex-direction: column;
  }
  .Grid .Left {
    width: 100%;
  }
  .Grid .Right {
    width: 100%;
  }
}
</style>
