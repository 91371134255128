<template>
  <div :class="'prompt ' + TooglePopupClass" @click="CheckPosition">
    <div>
      <h3>{{ $store.state.resource.Buttons.AddCourses }}</h3>
      <input
        type="text"
        v-model="Title"
        :placeholder="$store.state.resource.Buttons.Title"
      />
      <input
        type="text"
        v-model="PlayListUrl"
        :placeholder="$store.state.resource.Buttons.Video_Url"
      />
      <!-- <textarea v-model="Body"> </textarea> -->
      <div
        class="w-100 d-flex align-content-center justify-content-evenly mt-3"
      >
        <button class="btn BtnDesign AnimationBtn" @click="AddCourse">
          <font-awesome-icon icon="plus" />
          {{ $store.state.resource.Buttons.AddCourses }}
        </button>
        <button class="btn BtnDesignOutline AnimationBtn" @click="Cancel">
          <font-awesome-icon icon="arrow-right" />
          {{ $store.state.resource.ButtonCancel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CourseServices from "../../services/CourseServices.js";
export default {
  name: "AddCourse",
  props: ["Class"],
  data() {
    return {
      Title: "",
      PlayListUrl: "",
      TooglePopupClass: "",
    };
  },
  methods: {
    CheckPosition() {
      let This = this;
      window.onclick = function (event) {
        if (event.target.getAttribute("class") != null) {
          if (event.target.getAttribute("class").search("prompt") == 0) {
            This.$emit("SendClass", "");
          }
        }
      };
    },
    Cancel() {
      this.$emit("SendClass", "");
    },
    AddCourse() {
      if (this.Title != "" && this.PlayListUrl != "") {
        CourseServices.AddCourse(
          this.PlayListUrl,
          this.Title,
          this.$store.state.auth.user.id
        ).then((Val) => {
          if (Val == true) {
            this.$emit("SendData", "");
          }
        });
      }
      this.$emit("SendTooglePopupClass", "");
    },
  },
  watch: {
    Class(Val) {
      this.TooglePopupClass = Val;
    },
  },
};
</script>
