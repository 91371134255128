<template>
  <div class="pt-4 pb-5">
    <h1 class="text-center mb-3">المدونات الأكثر مشاهدة</h1>
    <div
      v-if="Load == false"
      class="spinner-border m-auto d-block text-primary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div
      class="d-flex justify-content-center align-items-center flex-wrap"
      v-if="Load == true"
    >
      <div
        class="card DirectionsForText p-0 mb-3 me-2"
        v-for="(BlogItem, i) in BlogItems"
        :key="'N' + i"
      >
        <img
          :src="require('@/assets/images/Image.jpeg')"
          class="card-img-top"
          height="165"
        />
        <div class="card-body">
          <h5 class="card-title">
            {{
              BlogItem.title.length >= 100
                ? BlogItem.title.slice(0, 100) + "..."
                : BlogItem.title
            }}
          </h5>
          <p class="card-text">
            {{
              BlogItem.body.length >= 50
                ? BlogItem.body.slice(0, 50) + "..."
                : BlogItem.body
            }}
          </p>
        </div>
        <div class="card-footer text-center">
          <router-link
            :to="'/Blog/' + BlogItem.id"
            class="btn btn-primary AnimationBtn"
          >
            <span>{{ $store.state.resource.ButtonRead }}</span>
            <font-awesome-icon icon="arrow-right" class="me-3" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogServices from "../../services/BlogServices";
export default {
  name: "MostViewedBlogs",
  data() {
    return {
      BlogItems: [],
      Load: false,
    };
  },
  methods: {
    getMultipleRandom(arr, num) {
      const shuffled = arr.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    },
  },
  created() {
    BlogServices.GetList().then((Val) => {
      this.BlogItems = this.getMultipleRandom(Val, 3);
      this.Load = true;
    });
  },
};
</script>
<style scoped>
.card {
  width: calc(95% / 3);
}
.card-img-top {
  object-fit: cover;
}
.card-body {
  height: 150px;
}
.card-text {
  font-size: 15px;
  font-weight: 500 !important;
}
@media (max-width: 1100px) {
  .card {
    width: calc(95% / 2);
  }
}
@media (max-width: 782px) {
  .card {
    width: 100%;
  }
}
</style>
