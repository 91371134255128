<template>
  <div class="Course">
    <div
      v-if="Show == false"
      class="spinner-border m-auto text-primary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <router-link tag="button" v-if="Show == true" :to="WhereToGo">
      <div class="LeftCourse" v-if="Show">
        <h3>{{ DataCourse.title }}</h3>
        <span>منذ : {{ creationDate }}</span>
      </div>
      <div class="RightCourse" v-if="Show">
        <img :src="Image" alt="" />
      </div>
    </router-link>

    <div v-if="Show && IsAdmin" class="PositionLeft">
      <button
        style="--Bg: var(--bs-primary)"
        class="AnimationBtn"
        @click="SendClassToShowPopupEdit"
      >
        <font-awesome-icon icon="edit" />
      </button>
      <button
        style="--Bg: var(--bs-danger)"
        class="AnimationBtn"
        @click="SendClassToShowPopupDel"
      >
        <font-awesome-icon icon="trash" />
      </button>
    </div>
  </div>
</template>
<script>
import CourseServices from "../../services/CourseServices";
export default {
  name: "ListItem",
  props: ["DataCourse"],
  data() {
    return {
      Image: "",
      creationDate: "",
      Url_Go: "",
      Show: false,
      WhereToGo: "",
      IsAdmin: false,
    };
  },
  created() {
    this.IsAdmin = CourseServices.IsAdmin(this.$store);
    if (
      this.$store.state.auth.user != null &&
      this.$store.state.auth.status.loggedIn
    ) {
      this.WhereToGo = `/Course/${this.DataCourse.youtubeListId}/${this.Url_Go}`;
    } else {
      this.WhereToGo = `/Login`;
    }
    const creationDate = new Date(this.DataCourse.creation_Date);
    this.creationDate = `${creationDate.getFullYear()}/${
      creationDate.getMonth() + 1
    }/${creationDate.getDate()}`;
    CourseServices.GetYouTubeListImage(this.DataCourse.youtubeListId).then(
      (Val) => {
        this.Image = Val;
      }
    );
    if (
      this.$store.state.auth.user != null &&
      this.$store.state.auth.status.loggedIn
    ) {
      CourseServices.GetVideosCourse(
        this.DataCourse.youtubeListId,
        this.$store.state.auth.user.id
      ).then((Val) => {
        var Data = Val.VidoesWatched.slice(Val.VidoesWatched.length - 1)[0];
        this.Url_Go = "/" + Data.snippet.resourceId.videoId;
        this.Show = true;
        this.WhereToGo = `/Course/${this.DataCourse.youtubeListId}${this.Url_Go}`;
      });
    } else {
      this.Show = true;
    }
  },
  methods: {
    SendClassToShowPopupDel() {
      this.$emit("AddClassForDel", this.DataCourse.youtubeListId);
    },
    SendClassToShowPopupEdit() {
      this.$emit("AddClassForEdit", {
        Title: this.DataCourse.title,
        PlayListUrl: this.DataCourse.youtubeListId,
      });
    },
  },
};
</script>

<style scoped>
.Course {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 500px;
  height: 180px;
  border: 2px solid var(--bs-primary);
  border-radius: 5px;
  text-decoration: none;
  color: #000;
  position: relative;
}
.Course a {
  display: flex;
  justify-content: center;
  overflow: hidden;
  color: #000;
  width: 100%;
  height: 100%;
}
.Course a .RightCourse {
  width: 50%;
  height: 100%;
}
.Course a .RightCourse img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Course a .LeftCourse {
  width: 50%;
}

.Course a .LeftCourse span {
  font-size: 15px;
  font-weight: 900;
}
.Course .PositionLeft {
  position: absolute;
  left: -60px;
  width: 60px;
  top: -1px;
  height: calc(100% + 2px);
  /* background: var(--bs-primary); */
  border-radius: 5px;
  opacity: 1;
  transform: scale(1);
  transition: var(--transitionSpeed);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.Course .PositionLeft button {
  width: 45px;
  height: 42px;
  border-radius: var(--radius5);
  border: 0;
  background: var(--Bg);
  color: #fff;
}

@media (max-width: 550px) {
  .Course a,
  .Course {
    flex-direction: column-reverse;
    height: auto;
    width: 100%;
  }
  .Course a .RightCourse,
  .Course a .LeftCourse {
    width: 100%;
  }
}
</style>
