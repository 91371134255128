<template>
  <div :class="'prompt ' + TooglePopupClass" @click="CheckPosition">
    <div>
      <h3>{{ $store.state.resource.Buttons.EditCourses }}</h3>
      <input type="text" v-model="NewTitle" />
      <input type="text" v-model="NewPlayListUrl" />
      <!-- <textarea v-model="Body"> </textarea> -->
      <div
        class="w-100 d-flex align-content-center justify-content-evenly mt-3"
      >
        <button class="btn BtnDesign AnimationBtn" @click="EditCourse">
          <font-awesome-icon icon="edit" />
          {{ $store.state.resource.Buttons.EditCourses }}
        </button>
        <button class="btn BtnDesignOutline AnimationBtn" @click="Cancel">
          <font-awesome-icon icon="arrow-right" />
          {{ $store.state.resource.ButtonCancel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CourseServices from "../../services/CourseServices.js";
export default {
  name: "EditCourse",
  props: ["Class", "Title", "PlayListUrl", "CourseId"],
  data() {
    return {
      TooglePopupClass: "",
      NewTitle: "",
      NewPlayListUrl: "",
    };
  },
  methods: {
    CheckPosition() {
      let This = this;
      window.onclick = function (event) {
        if (event.target.getAttribute("class") != null) {
          if (event.target.getAttribute("class").search("prompt") == 0) {
            This.$emit("SendClass", "");
          }
        }
      };
    },
    Cancel() {
      this.$emit("SendClass", "");
    },
    EditCourse() {
      if (this.NewTitle != "" && this.NewPlayListUrl != "") {
        // url, Title, id, UserId
        CourseServices.EditCourse(
          this.NewPlayListUrl,
          this.NewTitle,
          this.CourseId,
          this.$store.state.auth.user.id
        ).then((Val) => {
          if (Val == true) {
            this.$emit("SendData", "");
          }
        });
      }
    },
  },
  watch: {
    Class(Val) {
      this.TooglePopupClass = Val;
      this.NewTitle = this.Title;
      this.NewPlayListUrl = `https://www.youtube.com/playlist?list=${this.PlayListUrl}`;
    },
  },
};
</script>
