import axios from "axios";
const Url = "http://ysarkes-001-site1.gtempurl.com/api/Question/";
class QuestionsService {
  GetQuestion(VideoId) {
    return axios
      .get(`${Url}GetQuestion?VideoId=${VideoId}&UserId`)
      .then((val) => {
        return val.data;
      });
  }
  Add_Answer(QustionId, UserId, VideoId, Answer) {
    return axios
      .post(
        "http://ysarkes-001-site1.gtempurl.com/api/Question/AnswearQustion",
        {
          QustionId: QustionId,
          UserId: UserId,
          VideoId: VideoId,
          Answear: Answer,
        }
      )
      .then((val) => {
        return val;
      });
  }
  AddQuestion(YoutubeListId, VideoId, Content, UserId) {
    return axios
      .post(Url, {
        YoutubeListId: YoutubeListId,
        VideoId: VideoId,
        Content: Content,
        UserId: UserId,
      })
      .then(() => {
        return { qusetion: "", Class: "Prompt" };
      });
  }
  EditQuestion(YoutubeListId, VideoId, Content, UserId, QuestionId) {
    return axios
      .put(Url + QuestionId, {
        YoutubeListId: YoutubeListId,
        VideoId: VideoId,
        Content: Content,
        UserId: UserId,
      })
      .then(() => {
        return { qusetion: "", Class: "Prompt" };
      });
  }
  delQuestion(QuestionId) {
    return axios.delete(Url + QuestionId).then(() => {
      return { qusetion: "", Class: "Prompt" };
    });
  }
  // delete Sned QuestionId
}
export default new QuestionsService();
