<template>
  <div class="AboutMain">
    <h1>من نحن</h1>
    <div class="About">
      <div class="Left">
        <img :src="require(`@/assets/images/Website/About.jpg`)" />
      </div>
      <div class="Right DirectionsForText">
        <h3>{{ $store.state.resource.LableToAbout }}</h3>

        <p>{{ $store.state.resource.About }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
};
</script>
<style scoped>
.AboutMain {
  width: 100%;
  margin: 0 auto;
}
.AboutMain h1 {
  text-align: center;
}
.About {
  width: 96%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin: 60px auto;
}
.About .Left {
  width: 50%;
}
.About .Left img {
  width: 100%;
}
.About .Right {
  width: 50%;
  font-size: 20px;
  padding: 0 10px 10px 20px;
}
.About .Right h3 {
  margin: 0;
  border-bottom: 4px solid var(--bs-primary);
  margin-bottom: 20px;
  padding: 10px;
  display: block;
  width: 100%;
  border-radius: var(--radius5);
}
@media (max-width: 1260px) {
  .About .Left,
  .About .Right {
    width: 100% !important;
  }
}
</style>
