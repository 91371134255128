import { createStore } from "vuex";
import { auth } from "./auth.module";
import resourceAr from "../assets/Data/resourceAr.json";
const store = createStore({
  state: {
    resource: resourceAr,
    History: "/",
    Alert: "",
  },
  modules: {
    auth,
  },
});
export default store;
