<template>
  <main>
    <div class="card card-container">
      <h1>انشاء حساب جديد</h1>
      <div v-if="messages" class="w-100 text-center">
        <div
          v-for="(message, i) in messages"
          :key="'a' + i"
          class="alert w-100"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>
      <Form @submit="handleRegister" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group Input_DIv">
            <label for="email">البريد الألكتروني</label>
            <Field name="email" type="email" class="form-control" />
            <ErrorMessage name="email" class="text-danger error-feedback" />
          </div>
          <div class="form-group Input_DIv">
            <label for="password">كلمة المرور</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="text-danger error-feedback" />
          </div>
          <router-link to="/Login" class="d-block w-100 mb-3 text-center"
            >تسجيل الدخول</router-link
          >
          <div class="form-group Input_DIv">
            <button
              class="btn btn-primary AnimationBtn d-block w-75 mx-auto fs-5 fw-bolder"
              :disabled="loading"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              انشاء حساب جديد
            </button>
          </div>
        </div>
      </Form>
    </div>
  </main>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("البريد الالكتروني مطلوب!")
        .email("البريد الإلكتروني غير صالح!"),
      password: yup
        .string()
        .required("كلمة المرور مطلوبة!")
        .min(4, "يجب أن يكون على الأقل 4 أحرف!"),
    });
    return {
      successful: false,
      loading: false,
      messages: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleRegister(user) {
      this.messages = "";
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.messages = data.message;
          this.successful = true;
          this.loading = false;
          this.$router.push("/Login");
          this.$store.state.Alert = "تم انشاء حساب جديد بنجاح";
        },
        (error) => {
          this.messages =
            (error.response &&
              error.response.data &&
              error.response.data.errors) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
main {
  direction: rtl;
  margin-top: 70px;
  padding: 20px;
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;
}
main > div {
  height: auto;
  background: #fff;
  border-radius: var(--radius20);
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  position: relative;
}
main > div h1 {
  margin-bottom: 20px !important;
}

main > div form {
  width: 90%;
}
main form .Input_DIv {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

main form .Input_DIv span {
  font-size: 20px;
  font-weight: 500;
}
main form .Input_DIv input {
  width: 100%;
  height: 50px;
  border-radius: var(--radius5);
  border: 2px solid #ccc;
  outline: 0;
  padding: 20px 18px;
}
main form a {
  font-size: 23px;
  text-decoration: underline !important;
}
</style>
