<template>
  <div class="BlogerSaide">
    <div class="Show">
      <div
        v-if="Load == false"
        class="spinner-border m-auto d-block text-primary"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="Popular" v-if="Load == true">
        <h4 class="PositionCenter">الشائع قراءته</h4>
        <router-link
          :to="'/Blog/' + BlogItem.id"
          v-for="(BlogItem, i) in BlogItems"
          :key="'A' + i"
        >
          <img src="@/assets/images/Blog/2.jpg" alt="" />
          <div>
            <h5 class="card-title">
              {{
                BlogItem.title.length >= 35
                  ? BlogItem.title.slice(0, 35) + "..."
                  : BlogItem.title
              }}
            </h5>
            <p class="card-text">
              {{
                BlogItem.body.length >= 30
                  ? BlogItem.body.slice(0, 30) + "..."
                  : BlogItem.body
              }}
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BlogServices from "../../services/BlogServices";
export default {
  name: "LeftBlogBar",
  data() {
    return {
      BlogItems: [],
      Load: false,
    };
  },

  methods: {
    getMultipleRandom(arr, num) {
      const shuffled = arr.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    },
  },
  created() {
    BlogServices.GetList().then((Val) => {
      this.BlogItems = this.getMultipleRandom(Val, 3);
      this.Load = true;
    });
  },
};
</script>

<style scoped>
.BlogerSaide {
  width: 100%;
}

.BlogerSaide .Show {
  width: 100%;
}
.BlogerSaide .Show .Popular {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.BlogerSaide .Show .Popular h4 {
  border-bottom: 2px solid var(--bs-primary);
  margin-bottom: 20px;
  padding: 10px;
  display: block;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: var(--radius5);
}
.BlogerSaide .Show .Popular > a {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
  color: #000;
}
.BlogerSaide .Show .Popular > a img {
  width: 150px;
  border: 2px solid #ccc;
}
.BlogerSaide .Show .Popular > a > div > h5 {
  font-size: 18px;
}
.BlogerSaide .Show .Popular > a > div > p {
  font-size: 16px;
}
</style>
