<template>
  <div id="app">
    <NavBar />
    <RightSideBar />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import NavBar from "@/components/Layout/nav";
import RightSideBar from "@/components/Layout/RightSideBar";
import Footer from "@/components/Layout/Footer";
export default {
  name: "Home",
  components: {
    NavBar,
    RightSideBar,
    Footer,
  },
  created() {
    document.title = "history makers";
  },
  // computed: {
  //   currentUser() {
  //     return this.$store.state.auth.user;
  //   },
  //   showAdminBoard() {
  //     if (this.currentUser && this.currentUser["rolesName"]) {
  //       return this.currentUser["rolesName"].includes("ROLE_ADMIN");
  //     }
  //     return false;
  //   },
  //   showModeratorBoard() {
  //     if (this.currentUser && this.currentUser["rolesName"]) {
  //       return this.currentUser["rolesName"].includes("ROLE_MODERATOR");
  //     }
  //     return false;
  //   },
  // },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
</style>
