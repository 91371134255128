import axios from "axios";
const Url = "http://ysarkes-001-site1.gtempurl.com/Api/Post/";
class BlogService {
  GetDataFromToken(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  // This is For Admin
  AddBlogItem(Title, Body, Id) {
    if (Title != "" && Body != "" && Id != "") {
      return axios
        .post(Url, {
          Title: Title,
          Body: Body,
          UserId: Id,
        })
        .then(function (response) {
          console.log(response.data);
          return true;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  EditBlogItem(Id, Text, body, UserId) {
    return axios
      .put(Url + Id, {
        Title: Text,
        Body: body,
        UserId: UserId,
      })
      .then(function () {
        return true;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  DelBlogItem(Id) {
    return axios.delete(Url + Id).then((response) => {
      console.log(response.data);
      return true;
    });
  }
  // This is For Normal User
  GetList() {
    return axios.get(Url).then((response) => {
      return response.data;
    });
  }
  GetBlogItem(id) {
    return axios.get(Url + id).then((response) => {
      return response.data;
    });
  }
  IsAdmin(ThisStore) {
    if (ThisStore.state.auth.user != null) {
      return ThisStore.state.auth.user["rolesName"].includes("Admin");
    }
  }
  WhoRead(UserId, PostId) {
    axios
      .post(Url + "WhoViewPost", {
        PostId: PostId,
        UserId: UserId,
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new BlogService();
