<template>
  <!-- starts footer -->
  <footer>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="col-xl-3 col-lg-3 col-mg-12 mt-4" data-aos="fade-up">
          <div class="logo">
            <router-link to="/">
              <img src="@/assets/images/logo.png" alt="" />
            </router-link>
          </div>
          <div>
            <span>برنامج صٌناع التاريخ</span>
          </div>
        </div> -->
        <div
          class="offset-lg-1 offset-0 col-xl-3 col-lg-3 col-mg-12 mt-4"
          data-aos="fade-up"
        >
          <h3>تواصل معنا</h3>
          <ul>
            <li>
              <span>رقم الوتساب : 01229627620</span>
            </li>
            <li>
              <span>البريد الألكتروني: historymakerjourney@gmail.com</span>
            </li>
          </ul>
        </div>
        <div class="col-xl-3 col-lg-3 col-mg-12 mt-4" data-aos="fade-up">
          <h3>روابط مفيدة</h3>
          <ul>
            <li>
              <router-link to="/">الرئيسية</router-link>
            </li>
            <li>
              <router-link to="/Blog">المدونة</router-link>
            </li>
            <li>
              <router-link to="/Course/All">الكورسات</router-link>
            </li>
          </ul>
        </div>
        <div class="col-xl-3 col-lg-3 col-mg-12 mt-4" data-aos="fade-up">
          <h3>اشترك و انضم الينا</h3>
          <div class="media">
            <a href="https://bit.ly/3xDFDQQ" target="_blanck">
              <font-awesome-icon
                style="--color: #e52117"
                :icon="['fab', 'youtube']"
              />
            </a>
            <a href="https://bit.ly/36pF3Lh" target="_blanck">
              <font-awesome-icon
                style="--color: #1877f2"
                :icon="['fab', 'facebook']"
              />
            </a>
            <a href="#">
              <font-awesome-icon
                style="--color: #009649"
                :icon="['fab', 'whatsapp-square']"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="full d-none">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 text-center text-md-start">
            <p>© Copyright Arsha. All Rights Reserved</p>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 text-center text-md-end">
            <p>
              Designed by
              <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- ends footer -->
</template>

<script>
export default {
  name: "footer",
};
</script>
<style scoped>
/* starts footer */

footer {
  padding: 30px 0;
  background-color: #f3f5fa;
}

footer .logo {
  font-size: 40px;
}

footer .logo a {
  color: #37517e;
  text-transform: uppercase;
  font-weight: 500;
}

footer .logo + div span {
  display: block;
  margin: 4px;
  font-size: 15px;
}

footer .logo + div {
  margin-bottom: 10px;
}

footer .logo + div + div,
footer .logo + div + div + div {
  display: inline-flex;
  font-size: 14px;
  margin: 0;
}

footer .logo + div + div span,
footer .logo + div + div + div span {
  margin-right: 10px;
  font-weight: 900;
  text-transform: capitalize;
}

footer .logo + div + div p,
footer .logo + div + div + div p {
  margin: 0;
}

footer h3 {
  font-size: 20px;
  color: #37517e;
  font-weight: 900;
  margin-bottom: 20px;
}

footer h3 + ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

footer h3 + ul li {
  margin: 10px 0 !important;
  transition: 0.6s;
}

footer h3 + ul li a {
  color: #777777;
  text-transform: capitalize;
  font-weight: 900;
}

footer h3 + ul li:hover a {
  color: #47b2e4;
}

footer .media a {
  width: 45px;
  height: 45px;
  border-radius: var(--radius5);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #47b2e4;
  transition: var(--transitionSpeed);
  position: relative;
  z-index: 1;
  margin: 3px;
  font-size: 20px;
}
footer .media a svg {
  color: var(--color);
}

footer .media a:hover {
  transform: scale(1.1);
}

footer .media a:hover::after {
  width: 100%;
  height: 100%;
}

footer .full {
  background-color: #37517e;
  padding: 30px 0;
  color: #fff;
  margin-top: 30px;
}

footer .full p {
  margin: 0;
}

footer .full a {
  color: #47b2e4;
}

/* ends footer */
@media (max-width: 882px) {
  footer {
    width: 100%;
    margin: 70px auto 70px;
  }
}
</style>
