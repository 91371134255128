<template>
  <div>
    <button
      v-if="IsAdmin"
      @click="AddBlogItem"
      class="btn mx-auto BtnDesign AnimationBtn"
    >
      {{ $store.state.resource.Buttons.AddBlogItem }}
      <font-awesome-icon icon="plus" />
    </button>
    <div
      v-if="Load == false"
      class="spinner-border m-auto d-block text-primary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <AddBlogItem
      v-if="IsAdmin"
      :Class="ClassAddBlogItem"
      @SendClass="SendClass"
      @SendData="SendData"
    />
    <DelPopup
      v-if="IsAdmin"
      :Id="DataSendToPopupDel.Id"
      :Title="DataSendToPopupDel.Title"
      :Body="DataSendToPopupDel.Body"
      :Class="ClassDelBlogItem"
      @SendClass="SendClass"
      @SendData="SendData"
    />
    <EditPopup
      v-if="IsAdmin"
      :Id="DataSendToPopupEdit.Id"
      :Title="DataSendToPopupEdit.Title"
      :Body="DataSendToPopupEdit.Body"
      :Class="ClassEditBlogItem"
      @SendClass="SendClass"
      @SendData="SendData"
    />
    <div v-if="Load == true">
      <ListItem
        v-for="(BlogItem, i) in BlogItems"
        :key="'A_' + i"
        :Title="BlogItem.title"
        :Body="BlogItem.body"
        :Id="BlogItem.id"
        @SendDataForDel="SendDataForDel"
        @SendDataForEdit="SendDataForEdit"
      />
    </div>
  </div>
</template>
<script>
import ListItem from "./ListItem.vue";
import DelPopup from "./DelPopup.vue";
import EditPopup from "./EditPopup.vue";
import AddBlogItem from "./AddBlogItem.vue";
import BlogServices from "../../services/BlogServices.js";
export default {
  name: "List",
  components: {
    ListItem,
    DelPopup,
    EditPopup,
    AddBlogItem,
  },
  data() {
    return {
      BlogItems: "",
      IsAdmin: false,
      ClassAddBlogItem: "",
      ClassDelBlogItem: "",
      ClassEditBlogItem: "",
      DataSendToPopupEdit: "",
      DataSendToPopupDel: "",
      Load: false,
    };
  },
  methods: {
    AddBlogItem() {
      this.ClassAddBlogItem = "active";
    },
    SendClass() {
      this.ClassAddBlogItem = "";
      this.ClassDelBlogItem = "";
      this.ClassEditBlogItem = "";
    },
    SendData() {
      this.BlogItems = "";
      BlogServices.GetList().then((Val) => {
        this.BlogItems = Val;
      });
    },
    SendDataForDel(Val) {
      this.ClassDelBlogItem = "active";
      this.DataSendToPopupDel = Val;
    },
    SendDataForEdit(Val) {
      this.ClassEditBlogItem = "active";
      this.DataSendToPopupEdit = Val;
    },
  },
  created() {
    this.$store.state.History = `/Blog/`;
    document.title = "صناع التاريخ | المدونة";
    BlogServices.GetList().then((Val) => {
      this.BlogItems = Val;
      this.Load = true;
    });
    this.IsAdmin = BlogServices.IsAdmin(this.$store);
  },
};
</script>
