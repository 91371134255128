<template>
  <div class="Container_WebSite margin-top-And-Bottom-70">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Courses",
  created() {
    this.$store.state.History = "/Course/All";
    document.title = `صناع التاريخ | الكورسات `;
  },
};
</script>
