<template>
  <div class="mb-5">
    <h1 class="text-center m-4">الثمانية |٨| قيم الاساسية</h1>
    <div class="Values">
      <img
        v-for="(Value, i) in Values"
        :key="'A' + i"
        :src="require(`@/assets/images/8 core value icons/${Value.Src}`)"
        @click="ChangeMainValue(i)"
      />
    </div>
    <div :class="'showValue ' + Class">
      <h4>{{ MainValue }}</h4>
      <img
        v-if="MainValueSrc"
        :src="require(`@/assets/images/8 core value icons/${MainValueSrc}`)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TheEightValue",
  data() {
    return {
      MainValue: "",
      MainValueSrc: "",
      Class: "",
      Position: "",
      Class2: "",
      Values: [
        {
          Src: "01 الحميمية.png",
          Body: " يبحث الله عن رجال ونساء مكرسين يقودون من علاقة حميمة مع الله.",
        },
        {
          Src: "02 الولع.png",
          Body: 'يبحث الله عن رجال ونساء يشاركونهم الشغف لمن هم بدون المسيح. جاء يسوع "ليطلب الضالين ويخلصهم". يريد الله أن يتم الوصول إلى الجميع بقوة الإنجيل التي تغير الحياة.',
        },
        {
          Src: "03 القيادة.png",
          Body: " يبحث الله عن الرجال والنساء الملتزمين كتابياً برؤية وتحديد الأهداف وتعبئة جسد المسيح وتجاوز العقبات من أجل الوصول إلى الأمم من أجل المسيح",
        },
        {
          Src: "04 الكرازة.png",
          Body: "يبحث الله عن الرجال والنساء الذين يعيشون ويعلِّمون الإنجيل بملاءمة ثقافية وحساسية وقوة ، حتى تُفهم الحقيقة الأبدية للإنجيل وتقبل في كل ثقافة في العالم. ",
        },
        {
          Src: "05 التضاعف.png",
          Body: " يبحث الله عن الرجال والنساء الذين يتلمذون ويدربون ويوجهون قادة آخرين ، والذين بدورهم يصبحون قادة لقادة يقومون بتدريب الآخرين بشكل فعال.",
        },
        {
          Src: "06 الأسرة.png",
          Body: " يبحث الله عن رجال ونساء مقتنعين بأن الأسرة هي لبنة الله في المجتمع والذين يجعلون أسرهم أولوية في تنمية القادة.",
        },
        {
          Src: "07 الوكالة.png",
          Body: " يبحث الله عن رجال ونساء وكلاء مخلصين للأموال والوقت والهبات الروحية في حياتهم الشخصية وقيادتهم ونتيجة لذلك يتم الوصول إلى الناس بالإنجيل. ",
        },
        {
          Src: "08 الإستقامة.png",
          Body: "يبحث الله عن رجال ونساء أمناء يعيشون حياة مقدسة ويخضعون للمساءلة أمام الله وأمام جسد المسيح. النزاهة تمجد الله ، وتحمي القادة من التعثر ، وتشجع النمو.",
        },
      ],
    };
  },
  methods: {
    ChangeMainValue(IndexForArrayValues) {
      this.Class = "";
      setTimeout(() => {
        this.MainValue = this.Values[IndexForArrayValues].Body;
        this.MainValueSrc = this.Values[IndexForArrayValues].Src;
        this.Class = "Active";
        this.Class2 = "Active";
      }, 100);
    },
  },
};
</script>

<style scoped>
.Values {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  transition: var(--transitionSpeed);
}
.Values img {
  width: calc(94% / 8);
  height: 150px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 2px;
  cursor: pointer;
}
.showValue {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: #000 !important;
  width: 96%;
  margin: 10px auto;
  transform: scale(0);
  transition: var(--transitionSpeed);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.showValue img {
  width: 200px;
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.showValue.Active {
  transform: scale(1);
}
@media (max-width: 650px) {
  .Values img {
    width: calc(94% / 4);
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 2px;
    cursor: pointer;
  }
  .showValue img {
    width: 100px;
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .showValue h4 {
    font-size: 14px;
  }
}
</style>
