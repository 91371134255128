import axios from "axios";
const Url = "http://ysarkes-001-site1.gtempurl.com/Api/Courses/";
const YouTubeApiUrl = "https://youtube.googleapis.com/youtube/v3/";
import QuestionsServices from "./QuestionsServices";
class CourseService {
  GetDataFromToken(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  GetYouTubeListImage(youtubeListId) {
    return axios
      .get(
        `${YouTubeApiUrl}playlistItems?part=snippet&playlistId=${youtubeListId}&key=AIzaSyC3qGMINWWYbc1aqonrfDvsYQmzd83eGsM`
      )
      .then((Response) => {
        if (Response.data.items[0].snippet.thumbnails.medium != undefined) {
          return Response.data.items[0].snippet.thumbnails.medium.url;
        } else {
          return Response.data.items[1].snippet.thumbnails.medium.url;
        }
      });
  }
  AddCourse(PlayListUrl, Title, UserId) {
    return axios
      .post(Url, {
        url: PlayListUrl,
        youtubeListId: PlayListUrl.slice(
          PlayListUrl.indexOf("=") + 1,
          PlayListUrl.length
        ),
        userId: UserId,
        title: Title,
      })
      .then(() => {
        return true;
      });
  }
  GetList() {
    return axios
      .get(Url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.length == 0) {
          return false;
        } else {
          return response.data;
        }
      });
  }
  GetVideos(id) {
    return axios
      .get(
        `${YouTubeApiUrl}playlistItems?part=snippet&playlistId=${id}&maxResults=50&key=AIzaSyC3qGMINWWYbc1aqonrfDvsYQmzd83eGsM`
      )
      .then((Response) => {
        for (let i = 0; i < Response.data.items.length; i++) {
          if (
            Response.data.items[i].snippet.videoOwnerChannelTitle == undefined
          ) {
            Response.data.items.splice(i, 1);
            for (let i = 0; i < Response.data.items.length; i++) {
              if (
                Response.data.items[i].snippet.videoOwnerChannelTitle ==
                undefined
              ) {
                return Response.data.items.splice(i, 1);
              }
            }
          }
        }
        return Response.data.items;
      });
  }
  DelCourse(id) {
    return axios.delete(Url + id).then(() => {
      return true;
    });
  }
  EditCourse(url, Title, id, UserId) {
    return axios
      .put(Url + id, {
        URL: url,
        Id: 0,
        YoutubeListId: id,
        UserId: UserId,
        Title: Title,
      })
      .then((e) => {
        console.log(e);
        return true;
      });
  }
  GetVideoswatched(UserId, PlayListId) {
    return axios
      .post(`${Url}watchedVideos?UserId=${UserId}&PlayListId=${PlayListId}`)
      .then((Res) => {
        return Res.data;
      })
      .catch((error) => {
        return { error: error.response.data.title };
      });
  }
  GetVideosCourse(ListId, UserId, StatusUser) {
    let PlayListId = "";
    return axios.get(Url + ListId).then((response) => {
      PlayListId = response.data.youtubeListId;
      let This = this;
      return axios
        .get(
          `${YouTubeApiUrl}playlistItems?part=snippet&playlistId=${PlayListId}&maxResults=50&key=AIzaSyC3qGMINWWYbc1aqonrfDvsYQmzd83eGsM`
        )
        .then((Response) => {
          for (let i = 0; i < Response.data.items.length; i++) {
            if (
              Response.data.items[i].snippet.videoOwnerChannelTitle == undefined
            ) {
              Response.data.items.splice(i, 1);
              for (let i = 0; i < Response.data.items.length; i++) {
                if (
                  Response.data.items[i].snippet.videoOwnerChannelTitle ==
                  undefined
                ) {
                  Response.data.items.splice(i, 1);
                }
              }
            }
          }

          return This.GetVideoswatched(UserId != "" ? UserId : "No", PlayListId)
            .then((Val) => {
              let Watch = {
                Videos: [],
              };
              let VideosWatchLength = 0;
              for (let i = 0; i < Val.length; i++) {
                Watch.Videos.push(Val[i]);
              }
              for (let i = 0; i < Watch.Videos.length; i++) {
                if (
                  Watch.Videos[i].videoId ===
                  Response.data.items[i].snippet.resourceId.videoId
                ) {
                  if (Watch.Videos[i].questionId != 0) {
                    if (Watch.Videos[i].isAnsweared) {
                      VideosWatchLength++;
                    }
                  }
                }
              }
              if (StatusUser) {
                return {
                  Vidoes: Response.data.items,
                };
              } else {
                return {
                  Vidoes: Response.data.items.slice(
                    VideosWatchLength + 1,
                    Response.data.items.length
                  ),
                  VidoesWatched: Response.data.items.slice(
                    0,
                    VideosWatchLength + 1
                  ),
                };
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
    });
  }
  // GetVideoDataYouTubeApi(VideoId) {}
  IsAdmin(ThisStore) {
    if (ThisStore.state.auth.user != null) {
      return ThisStore.state.auth.user["rolesName"].includes("Admin");
    }
  }
  LoadOrWatchFuntion(VideoId) {
    return axios
      .get(
        `${YouTubeApiUrl}videos?part=snippet%2CcontentDetails%2Cstatistics&id=${VideoId}&key=AIzaSyC3qGMINWWYbc1aqonrfDvsYQmzd83eGsM`
      )
      .then((response) => {
        return QuestionsServices.GetQuestion(VideoId).then((Val) => {
          return {
            question: Val.content,
            QuestionID: Val.id,
            DataVideo: response.data.items[0].snippet,
            description2:
              response.data.items[0].snippet.description.length > 100
                ? response.data.items[0].snippet.description.slice(0, 100) +
                  "...."
                : response.data.items[0].snippet.description,
            description: response.data.items[0].snippet.description,
            title: response.data.items[0].snippet.title,
          };
        });

        // return axios
        //   .get(
        //     //Todo :  Move This funtion To Questions Services after create it
        //     `http://ysarkes-001-site1.gtempurl.com/api/Question/GetQuestion?VideoId=${VideoId}&UserId`
        //   )
        //   .then((val) => {});
      });
  }
  PostWatched(UserId, PlayListId, VideoId) {
    axios
      .post(`${Url}watched`, {
        UserId: UserId,
        PlayListId: PlayListId,
        VideoId: VideoId,
      })
      .then((Res) => {
        console.log(Res);
      });
  }
}
export default new CourseService();
