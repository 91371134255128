<template>
  <aside class="RightSideBar">
    <router-link to="/">
      <font-awesome-icon icon="home" />
      <span>{{ $store.state.resource.Buttons.Home }}</span>
    </router-link>
    <router-link to="/Blog"
      ><font-awesome-icon icon="blog" />
      <span>{{ $store.state.resource.Buttons.Bloger }}</span>
    </router-link>
    <router-link to="/Course/All">
      <font-awesome-icon icon="graduation-cap" />
      <span>{{ $store.state.resource.Buttons.Courses }}</span>
    </router-link>
  </aside>
</template>

<script>
export default {
  name: "RightSideBar",
};
</script>
